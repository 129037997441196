import { Color } from '@ionic/core';
import { environment } from '../../../environments/environment';
import { WaveformCache } from './WaveformCache';
import { WaveformSegmentBuffer } from './WaveformSegmentBuffer';

interface RGB {
  r: number,
  g: number,
  b: number,
  alpha: number
}
export class WaveformSegment {
  static segmentCount = -1;

  public constructor(width: number, height: number, msPxRatioProvider: { msPxRatio: number }) {
    this.msPxRatioProvider = msPxRatioProvider;
    WaveformSegment.segmentCount++;
    this.segmentIndex = WaveformSegment.segmentCount;

    this.internalCanvas = document.createElement('canvas');

    this.internalCanvas.width = width;
    this.internalCanvas.height = height;
    this.internalCtx = this.internalCanvas.getContext('2d');
    this.internalCtx.strokeStyle = 'blue';
    this.internalCtx.strokeRect(0, 0, width, height);

    this.internalCtx.fillStyle = 'red';
    this.internalCtx.font = 'italic 10pt Calibri';
    this.internalCtx.fillText('this is a segment', 5, height / 2);
  }

  // for debugging only
  segmentIndex: number;

  // segmentCanvas:HTMLCanvasElement = new HTMLCanvasElement();

  // used for drawing segment, result is then copied to mainCanvas in dual-waveform component
  internalCanvas: HTMLCanvasElement;
  internalCtx: CanvasRenderingContext2D;
  // x position in ms in regards to the song -> requires different calculations to get to pixel-position in cache/in waveform
  // in cache this is simply xPositionMs / msPxRatio
  xPositionMs = 0;

  // inialised on first getContext2D() call and cached
  ctx2d: CanvasRenderingContext2D = null;
  protected segmentBuffer: WaveformSegmentBuffer = new WaveformSegmentBuffer();
  msPxRatioProvider: { msPxRatio: number };

  // cached value set by dual-waveform-component
  /* private _segmentPositionCanvasPx:number;

  get segmentPositionCanvasPx()
  {
    return this._segmentPositionCanvasPx;
  }

  set segmentPositionCanvasPx(newPosXCanvasPx)
  {
    this._segmentPositionCanvasPx = newPosXCanvasPx;
  }*/

  private get msPxRatio(): number {
    return this.msPxRatioProvider.msPxRatio;
  }

  public getCanvas(): HTMLCanvasElement {
    return this.internalCanvas;
  }

  public getContext2D(): CanvasRenderingContext2D {
    if (this.ctx2d == null) {
      this.ctx2d = this.getCanvas().getContext('2d');
    }
    return this.ctx2d;
  }

  public getXPositionMs() {
    return this.xPositionMs;
  }

  public setXPositionMs(xPos: number) {
    this.xPositionMs = xPos;
  }

  public get xPositionPx(): number {
    return this.xPositionMs;
  }

  drawSegmentBackground() {
    this.getContext2D().clearRect(0, 0, this.getCanvas().width, this.getCanvas().height);

    // DEBUG: draw left/right border of segment
    /* this.getContext2D().strokeStyle = 'green';
    this.getContext2D().lineWidth = 1;
    this.getContext2D().strokeRect(
      this.getXPosition(),
      0,
      this.getCanvas().width - 1,
      this.getCanvas().height
    );
    this.getContext2D().stroke();*/
  }

  repaint(data: WaveformCache) {

    // console.log("data:WaveformCache: ", data);
    const byteArray: Int8Array = data.getByteArrayFromCacheObj();

    console.log(length);
    // copied value from WaveformSegmentBuffer.BYTES_PER_PIXEL in waveformcontrol6-fx
    const BYTES_PER_PIXEL = 8;
    const lineWidth = 1;
    const pixelCount = length / BYTES_PER_PIXEL;

    this.drawSegmentBackground();

    // todo_impl: // Zwei duenne Linien fuer die echten Null-Positionen zeichnen, unabhaengig von der Silence.
    // drawTrackLimits()

    // console.log('msPx-Ratio:%s', this.msPxRatio);

    // start px in global
    const xStartPx = Math.round(this.getXPositionMs() / this.msPxRatio);
    const xEndPx = Math.round(xStartPx + this.internalCanvas.width);

    let lastPx = 0;

    const scaleVals = 1 / Math.sqrt(2);

    // console.log('painting segment %s: xStart: %s until xEnd %s', this.segmentIndex, xStartPx, xEndPx);

    if (!(xEndPx < 0)) {
      // clear waveform first (drawSegmentBackground()), then only paint over where there is data
      // x - pixel position to paint
      // i - position in byteArray to get data from
      for (let x = xStartPx, i = xStartPx * BYTES_PER_PIXEL; i + 7 < byteArray.length && x < xEndPx; x++, i += BYTES_PER_PIXEL) {
        if (x < 0) {
          continue;
        }

        const minValue = byteArray[i] * scaleVals - 0.5;
        const maxValue = byteArray[i + 1] * scaleVals + 0.5;
        
        const avgNegative = byteArray[i + 2] * scaleVals - 0.5;
        const avgPositive = byteArray[i + 3] * scaleVals + 0.5;

        const middle = this.internalCanvas.height;
        const scale = this.internalCanvas.height / 255.0;
        // Durch die Rundung werden die sehr kleinen Werte um 1 Pixel besser sichtbar.
        // Bei den negativen Werten erzeugt nur -0.5 symetrische Bilder, auch round() ist falsch.
        // const maxY1 = middle - (maxValue * scale); // top of line, 0.5  is like ceil
        // let minY2 = middle - (minValue * scale - 0.5); // bot of line, -0.5 is like floor
        // var lineH = maxY1 - minY2 + 1;//why +1?

        // calculate min max color from low, mid and high pass
        const lowPassAvg = Math.max(0, Math.min(255, byteArray[i + 4] * 2 + 0.5));
        const midPassAvg = Math.max(0, Math.min(255, byteArray[i + 5] * 2 + 0.5));
        const highPassAvg = Math.max(0, Math.min(255, byteArray[i + 6] * 2 + 0.5));
        const minMaxColorRGB : RGB = {
          r : Math.max(0.0, Math.min(255, lowPassAvg)),
          g : Math.max(0.0, Math.min(255, midPassAvg)),
          b : Math.max(0.0, Math.min(255, highPassAvg)),
          alpha : 255
        }
        const minMaxColor = this.rgbToHex(minMaxColorRGB);  

        //calculate average color by blending between main color (orange for now) and the min max color
        const mainColor = 'ffa500';
        let averageColor = this.blendBetweenTwoColors(minMaxColorRGB, this.hexToRgb(mainColor));

        // //draw the min max waveform
        const lineH = Math.abs(minValue * scale) + Math.abs(maxValue * scale) + 1; // why +1
        this.drawRectangle(x - xStartPx, middle, lineWidth, lineH, minMaxColor);

        //draw the average waveform
        const averageH = Math.abs(avgNegative) * scale + Math.abs(avgPositive) * scale;
        this.drawRectangle(x - xStartPx, middle, lineWidth, averageH, this.rgbToHex(averageColor));
      }
    }

    if (environment.debugDualWaveform) {
      // this.getContext2D().strokeStyle = '#00ffff55';
      // this.getContext2D().lineWidth = 1;
      // this.getContext2D().strokeRect(0, 0, this.getCanvas().width - 1, this.getCanvas().height);
      // // this.getContext2D().stroke();

      // this.internalCtx.fillStyle = 'red';
      // this.internalCtx.font = 'italic 10pt Calibri';
      // const text = 'segment ' + this.segmentIndex;
      // this.internalCtx.fillText(text, 5, this.getCanvas().height - 10);
    }
  }

  private rgbToHex(color : RGB) {
    return "#" + (1 << 24 | color.r << 16 | color.g << 8 | color.b).toString(16).slice(1);
  }

  private hexToRgb(hex : string) : RGB {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      alpha: 255
    } 
  }

  private drawRectangle(x : number, y : number, lineWidth : number, lineHeight : number, hexColor : string) {
    this.getContext2D().beginPath();
    this.getContext2D().rect(x, y, lineWidth, lineHeight * -1);
    this.getContext2D().fillStyle = hexColor;
    this.getContext2D().fill();
  }


  private blendBetweenTwoColors(c0: RGB, c1: RGB) : RGB {
    let c1Alpha = c1.alpha/2.0;
    let totalAlpha = c0.alpha + c1Alpha;        
    let weight0 = c0.alpha / totalAlpha;
    let weight1 = c1Alpha / totalAlpha;

    return {
      r : Math.min(weight0 * c0.r + weight1 * c1.r, 255),
      g : Math.min(weight0 * c0.g + weight1 * c1.g, 255),
      b : Math.min(weight0 * c0.b + weight1 * c1.b, 255)
    } as RGB

  }
}
