import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AudioContext } from 'angular-audio-context';
import { Observable, Subject, Subscription, forkJoin, interval, of } from 'rxjs';
import { catchError, concatMap, first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { setCurrentPlayingBarAction } from '../core/redux/actions/beatmaker.actions';
import { UMSoundModule, Player, Sound, UMSoundOutputType, UMSoundResult, ScratchingInformation } from '@onexip/capacitor-umsound';
import { getPattern } from '../core/redux/reducers/beatmaker.reducer';
import { BeatMakerPattern } from '../main/beatmaker/beat-maker-pattern';
import { BeatMakerTrack } from '../main/beatmaker/beat-maker-track';
import { WaveformCache } from '../main/waveform/WaveformCache';
import { Track } from '../music-archive/track';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Buffer } from 'buffer';
import { UMSoundInputTransformerService } from './umsound-input-transformer.service';
import { getLoopBeatRange, getPitchMode, getWaveformCache } from '../core/redux/reducers/player.reducer';
import { PitchMode, PitchModes } from '../models/pitch-mode';
import { WaveformCacheI } from '../main/waveform/WaveformCacheI';
import { Beat } from '../main/waveform/WaveformCacheI';
import { setLoopSuccessfulAction, setTimeAction, toggleLoopAction, updateWaveformDataAction } from '../core/redux/actions/player.actions';
import { setLevelsAction } from '../core/redux/actions/mixer.actions';

@Injectable({
  providedIn: 'root',
})
export class AudioService implements OnDestroy {
  // TimeMessurement
  private sumTime = 0;
  private valueCounter = 0;

  private timerSubs: Subscription[] = [];

  public static timeRate = 12;

  filePath: string;

  private patternSubscription: Subscription;

  private players: Array<Player> = new Array<Player>(10);
  private destroyLastPitch$: Subject<void> = new Subject();
  private waveDataChanged$: Subject<Int8Array>;
  private beats: Beat[];
  private loopRangeChanged$: Observable<number>;

  constructor(
    private http: HttpClient,
    private store: Store,
    private transformer: UMSoundInputTransformerService
  ) {
    console.log('creating system frontend');
    UMSoundModule.createSystem$()
      .pipe(
        switchMap((sound: Sound) => {
          console.log(sound);
          return sound.init$(UMSoundOutputType.AUTO_DETECT).pipe(map(() => sound));
        }),
        switchMap((sound: Sound) => {
          console.log('createPlayer');
          return sound.createPlayer$().pipe(map((player) => [player, sound]));
        }),
        switchMap(([player, sound]: [Player, Sound]) => {
          console.log('Player initialize');
          return player.initialize_easy$(sound.getAddress()).pipe(map(() => player));
        }),
        tap((player: Player) => {
          console.log(player); 
          this.players[1] = player;
          this.waveDataChanged$ = new Subject<Int8Array>();
          player.registerWaveDataUpdate(this.waveDataChanged$);
          this.waveDataChanged$.subscribe((data) => {
            console.log('updating wave data frontend');
            let formData: WaveformCacheI = {
              playerID: 1,
              cache: {
              version: '2',
              filename: 'any',
              length: '8192',
              waveform: Buffer.from(data).toString('base64'),
              maximum: '256',
              average: '50',
              firstBeat: '-1',
              beatInterval: '0.4653968253968254',
              beatPhaseShift: '0.11873015873015874',
              beats: this.beats,
              },
              added: true,
            };
            let waveform = new WaveformCache(formData);
            this.store.dispatch(updateWaveformDataAction({ playerID: 1, waveformCache: waveform }));
          })
        })
      ).subscribe();
  }

  private startLevelsAndTimeSim(playerID: number){
    this.timerSubs[playerID] = interval(AudioService.timeRate).subscribe((value) => {  
      // TODO: combine levels and position to only one call for better performance!
      let levels = this.getLevels(playerID);
      this.store.dispatch(setLevelsAction({ playerID: 0, levelLeft: 1 - levels[0], levelRight: 1 - levels[1] }));
  
      let pos = this.getPosition(playerID);
      this.store.dispatch(setTimeAction({ playerID: playerID, time: this.players[playerID].pcmToMs(pos) }));
    })
  }

  private stopLevelsAndTimeSim(playerID: number) {
    if (this.timerSubs[playerID]) {
      this.timerSubs[playerID].unsubscribe();
    }
  }

// if (timerSub) {
//     timerSub.unsubscribe();
// }


  setFilePath(filePath: string): void {
    this.filePath = filePath;
  }

  ngOnDestroy(): void {
    this.patternSubscription.unsubscribe();
    this.stopLevelsAndTimeSim(1);
  }

  // todo: change Play function
  play(playerID: number) {
    this.players[playerID].play();
    this.players[playerID].startUpdateLoop(12);
    this.stopLevelsAndTimeSim(playerID);
    this.startLevelsAndTimeSim(playerID);
  }

  loadTestFile(): void {
    // return this.umCore.loadTestFile();
  }

  isPlaying(playerID: number): boolean {
    // return UMCore.isPlaying(playerID);
    return true;
  }

  unload(playerID: number): number {
    // return this.umCore.unload(playerID);
    return 1;
  }

  getLength(playerID: number): number {
    //return UMCore.getLength(playerID);
    return 1;
  }

  getPosition(playerID: number): number {
    let pos =  this.players[playerID].getPosition();
    return pos;
  }

  getPositionMs(playerID: number): number {
    return this.players[playerID].pcmToMs(this.players[playerID].getPosition());
  }

  setPosition(playerID: number, timeinmillis: number): number {
    // this.player.setPosition;
    return 1;
  }

  setIsReverse(playerID: number, reverse: boolean): number {
    // return this.umCore.setIsReverse(playerID, reverse);
    return 1;
  }

  getVolume(playerID: number): number {
    // return this.umCore.getVolume(playerID);
    return 1;
  }

  getLevels(playerID: number): Float32Array {
    return this.players[playerID].getLevels();
  }

  getWaveData$(playerID: number, bufferSize: number, startPos: number): Observable<Int8Array> {
    return this.players[playerID].getWaveData$(bufferSize, startPos);
  }

  getBeats(playerID: number): Observable<Beat[]> {
    return this.players[playerID].getBeats$();
  }

  setVolume(playerID: number, volume: number) {
    this.players[playerID].setVolume(this.transformer.volume(volume));
  }

  setScratchIntensity(playerID: number, intensity: number) {
    this.players[playerID].setScratchIntensity(intensity);
  }

  /**
   *
   * @param playerID
   * @param pitch -100 - 100
   */
  setPitchOrTempo(playerID: number, value: number): void {
    this.destroyLastPitch$.next();
    this.store
      .select(getPitchMode(playerID))
      .pipe(
        map((pitchMode) => {
          switch (pitchMode) {
            case PitchModes.PITCH:
              this.setPitch(playerID, value);
              break;
            case PitchModes.TEMPO:
              this.setTempo(playerID, value);
              break;
          }
        }),
        takeUntil(this.destroyLastPitch$)
      )
      .subscribe();
  }

  setTempo(playerID: number, value: number) {
    this.players[playerID].setTimeStretch(this.transformer.tempo(value));
  }

  setPitch(playerID: number, value: number): void {
    this.players[playerID].setPitch(this.transformer.pitch(value));
  }

  setScratch(playerID: number, pitch: number): void {
    this.players[playerID].setPitch(pitch);
  }

  getVolumeMonitor(playerID: number): number {
    //return UMCore.getVolumeMonitor(playerID);
    return 10;
  }

  setVolumeMonitor(playerID: number, volume: number): number {
    //return UMCore.setVolumeMonitor(playerID, volume);
    return 1;
  }

  setMute(playerID: number, enable: boolean): number {
    //return UMCore.setMute(playerID, enable);
    return 1;
  }

  setMuteMonitor(playerID: number, enable: boolean): number {
    //return UMCore.setMuteMonitor(playerID, enable);
    return 1;
  }

  setGain(playerID: number, gain: number): number {
    //return UMCore.setGain(playerID, gain);
    return 1;
  }

  setPan(playerID: number, pan: number): number {
    //return UMCore.setPan(playerID, pan);
    return 1;
  }

  setLoop(playerID: number, loop: boolean, setposition: boolean): number {
    //return UMCore.setLoop(playerID, loop, setposition);
    return 1;
  }

  setLoopIn(playerID: number) {
    const pos = this.getPosition(playerID);
    console.log('setLoopIn', pos);
    this.players[playerID].setLoopIn(pos);
  }
  
  setLoopOut(playerID: number) {
    const pos = this.getPosition(playerID);
    let started = this.players[playerID].setLoopOut(pos).started;
    console.log('setLoopOut', pos, started);
    let loopInPosMs = this.players[playerID].pcmToMs(this.players[playerID].loopInPos);
    let loopOutPosMs = this.players[playerID].pcmToMs(this.players[playerID].loopOutPos);
    this.store.dispatch(setLoopSuccessfulAction({ playerID: playerID, loop: started, start: loopInPosMs, end: loopOutPosMs}));
  }

  setLoopPoints(playerID: number, start: number, end: number) {
    this.players[playerID].startLoop(Math.floor(start), Math.floor(end));
    this.store.dispatch(setLoopSuccessfulAction({ playerID: playerID, loop: true, start: start, end: end }));
  }

  endLoop(playerID: number) {
    this.players[playerID].endLoop();
  }

  loopBeat(playerID: number) {
    let {startTime, endTime, started} = this.players[playerID].loopBeat();
    console.log('loopBeat', startTime, endTime, started);
    this.store.dispatch(setLoopSuccessfulAction({ playerID: playerID, loop: started, start: startTime, end: endTime }));
  }

  setLoopBeatRange(playerID: number, beatRange: number) {
    console.log('setLoopBeatRange', beatRange, playerID);
    console.log(this.players[playerID]);
    this.players[playerID].setLoopBeatRange(beatRange);
  }

  pause(playerID: number): number {
    //return UMCore.pause(playerID);
    return 1;
  }

  // todo change stop
  stop(playerID: number) {
    this.stopLevelsAndTimeSim(playerID);
    this.players[playerID].stop();
  }

  SKF(keyFile: string, purchaseID: number): boolean {
    // return this.umCore.SKF(keyFile, purchaseID);
    return true;
  }

  cleanUp(): void {
    //return UMCore.cleanUp();
  }

  enableVirtualVinyl(playerID: number, enable: boolean): number {
    //return UMCore.enableVirtualVinyl(playerID, enable);
    return 1;
  }

  setVirtualVelocity(playerID: number, positionMs: number, timems: number): number {
    //return UMCore.setVirtualVelocity(playerID, positionMs, timems);
    return 1;
  }

  setVirtualVelocityAvgTime(playerID: number, positionMs: number, timems: number, received: number): number {
    this.sumTime += window.performance.now() - received;
    this.valueCounter += 1;

    //return UMCore.setVirtualVelocity(playerID, positionMs, timems);
    return 1;
  }
  setVirtualVTMode(playerID: number, mode: number): number {
    //return UMCore.setVirtualVTMode(playerID, mode);
    return 1;
  }

  setVirtualVTInertia(playerID: number, inertia: number): number {
    //return UMCore.setVirtualVTInertia(playerID, inertia);
    return 1;
  }

  getVirtualVTMode(playerID: number): number {
    //return UMCore.getVirtualVTMode(playerID);
    return 1;
  }

  loadTrack$(playerID: number, track: Track): Observable<WaveformCache> {
    this.loopRangeChanged$ = this.store.select(getLoopBeatRange(playerID)).pipe(
      tap((loopRange) => {
        if (loopRange) {
          console.log('loopRange', loopRange);
          this.setLoopBeatRange(playerID, loopRange);
        }
      })
    );
    this.loopRangeChanged$.subscribe();

    this.stopLevelsAndTimeSim(playerID);
    return this.loadingAudioData$(playerID, track).pipe(
      switchMap(() => {
        return this.loadWaveformData$(playerID);
      })
    );
  }

  private loadingAudioData$(playerID: number, track: Track): Observable<UMSoundResult> {
    //return this.http.get(track.src, { responseType: 'blob' as 'json' }).pipe(
    //https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4"
    //https://elasticbeanstalk-eu-central-1-119453223328.s3.eu-central-1.amazonaws.com/ultramixer/The_Admirals_featuring_Seraphina_Bass!Man2010_UltraMixerEdit-optimized.mp4
    return this.http.get("https://elasticbeanstalk-eu-central-1-119453223328.s3.eu-central-1.amazonaws.com/ultramixer/The_Admirals_featuring_Seraphina_Bass!Man2010_UltraMixerEdit-optimized.mp4", { responseType: 'blob' as 'json' }).pipe(
      switchMap((data: Blob) => {
        return this.convertToBase64(data);
      }),
      switchMap((base64Data: string) => {
        return Filesystem.writeFile({
          path: 'track.mp4', //needs to be changed to cache the songs later
          data: base64Data,
          directory: Directory.Documents,
        });
      }),
      map((fileResult) => {
        return fileResult.uri.split('//')[1];
      }),
      switchMap((path) => {
        // this.player.openVideo$(path).subscribe();
        return this.players[playerID].loadSound$(path, track.src);
        // return this.players[playerID].loadSound$("https://elasticbeanstalk-eu-central-1-119453223328.s3.eu-central-1.amazonaws.com/ultramixer/The_Admirals_featuring_Seraphina_Bass!Man2010_UltraMixerEdit-optimized.mp4");
      }),
      catchError((e) => {
        console.log(e);
        return of(null);
      })
    );
  }

  private convertToBase64(data: Blob): Observable<string> {
    return of(null).pipe(
      switchMap(() => {
        return data.arrayBuffer();
      }),
      map((buffer) => {
        return Buffer.from(buffer).toString('base64');
      })
    );
  }

  setFlanger(playerID: number, value: number, active: boolean) {
    value = Math.round(value);
    let rate = 0.1;
    let depth = 1;
    this.players[playerID].setFlanger(depth, rate, this.transformer.flangerMix(value));
  }

  setResonance(playerID: number, value: number, active: boolean) {
    value = Math.round(value);
    this.players[playerID].setResonance(this.transformer.resonance(value));
  }

  setCutoff(playerID: number, value: number, active: boolean) {
    value = Math.round(value);
    this.players[playerID].setCutoff(this.transformer.cutoff(value));
  }

  setEQHigh(playerID: number, value: number, active: boolean) {
    value = Math.round(value);
    this.players[playerID].setEQHigh(this.transformer.eq(value));
  }

  setEQMid(playerID: number, value: number, active: boolean) {
    value = Math.round(value);
    this.players[playerID].setEQMid(this.transformer.eq(value));
  }

  setEQLow(playerID: number, value: number, active: boolean) {
    this.players[playerID].setEQLow(this.transformer.eq(value));
  }

  killEqHigh(playerID: number) {
    this.players[playerID].killEQHigh();
  }

  killEqMid(playerID: number) {
    this.players[playerID].killEQMid();
  }

  killEqLow(playerID: number) {
    this.players[playerID].killEQLow();
  }

  killResonance(playerID: number) {
    this.players[playerID].killResonance();
  }

  killFlanger(playerID: number) {
    this.players[playerID].killFlanger();
  }

  killCutoff(playerID: number) {
    this.players[playerID].killEQHigh();
  }

  setZoom(playerID: number, msPerPx: number) {
    this.players[playerID].setMsPerPixel(msPerPx);
  }

  loadWaveformData$(playerID: number): Observable<WaveformCache> {
    return this.getWaveData$(playerID, 4096, 0).pipe(
      tap((data) => {
        console.log('WaveData:', data);
      }),
      switchMap((data) =>
      this.getBeats(playerID).pipe(
        map((beats) => {
          this.beats = beats;
          console.log('Beats:', beats);
          let formData: WaveformCacheI = {
            playerID: playerID,
            cache: {
            version: '2',
            filename: 'any',
            length: '8192',
            waveform: Buffer.from(data).toString('base64'),
            maximum: '256',
            average: '50',
            firstBeat: '-1',
            beatInterval: '0.4653968253968254',
            beatPhaseShift: '0.11873015873015874',
            beats: beats,
            },
            added: false,
          };
        return new WaveformCache(formData);
        })
      )
      )
    );
  }

  playSampleSlot(sampleSlot: number) {
    //TODO 
  }

  setCrossfader(value: number) {
    {
      this.setVolume(0, value - 10000);
      this.setVolume(1, value);
    }
  }

  scratchStart(playerID: number, currInfo: ScratchingInformation) {
    this.players[playerID].startScratch(currInfo);
  }

  scratchEnd(playerID: number) {
    this.players[playerID].endScratch();
  }

  scratchOnWaveform(playerID: number, currInfo: ScratchingInformation) {
    this.players[playerID].scratch(currInfo);
  }

}