export class Track {
  id?: string;
  _id: string;
  src: string;
  artist: string;
  title: string;
  length: number;
  trackNumber: number;
  bpm?: number;
  rating?: number;
  coverUrl?: string;
  cuepoints?: number[];

  // TODO: brauhen wir so nicht
  constructor(
    src: string,
    artist: string,
    title: string,
    length: number,
    trackNumber: number,
    bpm: number,
    rating: number,
    coverUrl?: string,
    cuepoints?: number[]
  ) {
    this.src = src;
    this.artist = artist;
    this.title = title;
    this.length = length;
    this.trackNumber = trackNumber;
    this.bpm = bpm;
    this.rating = rating;
    this.coverUrl = coverUrl;
    this.cuepoints = cuepoints;
  }
}
