<div class="root">
  <div class="waveform" #waveformContainer>
    <!---<canvas #bgCanvas [width]="waveformWidth$ | async" [height]="waveformHeight$ | async" class="waveform-bg"></canvas>//-->
    <canvas #bgCanvas width="100%" height="100%" class="waveform-bg"></canvas>
    <div class="waveforms">
      <canvas #p1Canvas [width]="waveformWidth$ | async" [height]="(waveformHeight$ | async) / 2"></canvas>
      <canvas #p2Canvas [width]="waveformWidth$ | async" [height]="(waveformHeight$ | async) / 2"></canvas>
    </div>

    <canvas class="waveform-fg" #fgCanvas [width]="waveformWidth$ | async"
      [height]="(waveformHeight$ | async)"></canvas>
    <!--
    <canvas class="waveform-fg" #fgCanvas width="100%" height="100%"></canvas>
    //-->
  </div>

  <div class="test-buttons">
    <um-button class="mini-button" toggle="false" (click)="zoomIn()">zoom +</um-button>
    <um-button class="mini-button" toggle="false" (click)="zoomOut()">zoom -</um-button>
  </div>

  <div class="test-buttons" *ngIf="debugMode">
    <um-button class="mini-button" toggle="false" (click)="movePos100ms()">move +200ms</um-button>
    <um-button class="mini-button" toggle="false" (click)="moveNeg100ms()">move -200ms</um-button>
    <um-button class="mini-button" toggle="false" (click)="handlePlay()">play</um-button>
    <um-button class="mini-button" toggle="false" (click)="handlePause()">pause</um-button>
  </div>
</div>