import { WaveformCacheI } from './WaveformCacheI';

export class WaveformCache implements WaveformCacheI {
  cache: import('./WaveformCacheI').Cache;
  readonly BYTES_PER_PIXEL: number = 8;
  cachedB64DecodedCache: string;
  pixelCount: number;
  length = 0;
  playerID: number;

  constructor(data: WaveformCacheI) {
    this.playerID = data.playerID;
    this.cache = data.cache;
    this.cachedB64DecodedCache = atob(this.cache.waveform);
    this.length = this.cachedB64DecodedCache.length;
    this.pixelCount = this.length / this.BYTES_PER_PIXEL;
    this.added = data.added;
  }

  getPlayerID(): number {
    return this.playerID;
  }

  added: boolean;

  public getByteArrayFromCacheObj(): Int8Array {
    // todo: possiby split waveformCache into WaveformCacheI (interface) and WaveformCache (class implementation with utility functions
    const length = this.cachedB64DecodedCache.length;
    const byteArray: Int8Array = new Int8Array(length);
    for (let i = 0; i < length; i++) {
      byteArray[i] = this.cachedB64DecodedCache.charCodeAt(i);
    }
    return byteArray;
  }

  public appendWaveformCache(waveData: string) {
    this.cache.waveform = this.cache.waveform + waveData;
    this.cachedB64DecodedCache = this.cachedB64DecodedCache + atob(waveData);
    this.length = this.cachedB64DecodedCache.length;
    this.pixelCount = this.length / this.BYTES_PER_PIXEL;
  }
}
