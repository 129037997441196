import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from '../../components/components.module';
import { UMButtonComponent } from '../../components/umbutton/umbutton.component';
import { BSWaveformComponent } from './bs-waveform/bs-waveform.component';
import { DualWaveformComponentv1Component } from './dual-waveform-v1/dual-waveform-v1.component';
import { DualWaveformComponent } from './dual-waveform/dual-waveform.component';
import { WaveformImplementationTestComponent } from './waveform-implementation-test/waveform-implementation-test.component';
import { WaveformSegmentComponent } from './waveform-segment/waveform-segment.component';
import { WaveformComponent } from './waveform/waveform.component';

@NgModule({
  declarations: [
    WaveformComponent,
    BSWaveformComponent,
    DualWaveformComponent,
    DualWaveformComponentv1Component,
    WaveformSegmentComponent,
    WaveformImplementationTestComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    IonicModule,
    UMButtonComponent,
    //StoreModule.forFeature('player1', reducer),
    //StoreModule.forFeature('player2', reducer),
  ],
  exports: [
    WaveformComponent,
    BSWaveformComponent,
    DualWaveformComponent,
    DualWaveformComponentv1Component,
    WaveformImplementationTestComponent,
  ],
})
export class WaveformModule {}
