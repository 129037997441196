import { createAction, props } from '@ngrx/store';
import { WaveformCache } from '../../../main/waveform/WaveformCache';
import { Track } from '../../../music-archive/track';
import { PitchMode } from '../../../models/pitch-mode';
import { ScratchingInformation } from '../../interfaces/scratching.interface';

const ACTION_TYPE = '[Player]';

export const loadIntoPlayerAction = createAction(`${ACTION_TYPE} Load into Player`, props<{ playerID: number; track: Track }>());

export const loadIntoPlayerSuccessfullAction = createAction(
  `${ACTION_TYPE} Load into Player successful`,
  props<{ playerID: number; track: Track; waveformData: WaveformCache }>()
);

export const loadIntoPlayerFailedAction = createAction(
  `${ACTION_TYPE} Load into Player failed`,
  props<{ playerID: number; track: Track; error: any }>()
);

export const setTimeAction = createAction(`${ACTION_TYPE} Set Time`, props<{ playerID: number; time: number }>());
export const setTimeUM6Action = createAction(
  `${ACTION_TYPE} Set Time UM6`,
  props<{ playerID: number; time: number; remainingTime: number }>()
);
export const setTrackTitleUM6Action = createAction(
  `${ACTION_TYPE} Set Track title UM6`,
  props<{ playerID: number; artist: string; title: string; bpm: number; key: string; trackNumber: number; coverAsBase64: string }>()
);

export const playAvgTimeAction = createAction(`${ACTION_TYPE} PlayAvgTime`, props<{ playerID: number; received: number }>());
export const playAction = createAction(`${ACTION_TYPE} Play`, props<{ playerID: number }>());
export const cueAction = createAction(`${ACTION_TYPE} Cue`, props<{ playerID: number }>());
export const toggleLoopAction = createAction(`${ACTION_TYPE} toggle activity of loop`, props<{ playerID: number; start: number; end: number}>());
export const loopBeatAction = createAction(`${ACTION_TYPE} Loop Beat`, props<{ playerID: number }>());
export const increaseLoopBeatAction = createAction(`${ACTION_TYPE} Increase Loop Beat`, props<{ playerID: number }>());
export const decreaseLoopBeatAction = createAction(`${ACTION_TYPE} Decrease Loop Beat`, props<{ playerID: number }>());
export const loopInAction = createAction(`${ACTION_TYPE} Loop In`, props<{ playerID: number; time: number }>());
export const loopOutAction = createAction(`${ACTION_TYPE} Loop Out`, props<{ playerID: number; time: number }>());
export const loopAction = createAction(`${ACTION_TYPE} Loop`, props<{ playerID: number; loop: boolean }>());
export const setLoopSuccessfulAction = createAction(`${ACTION_TYPE} loop successful`, props<{ playerID: number; loop: boolean, start: number, end: number }>());
export const setCuePointAction = createAction(`${ACTION_TYPE} Set Cue Point`, props<{ playerID: number; cuepointIndex: number }>());
export const saveCuePointAction = createAction(`${ACTION_TYPE} Save Cue Point`, props<{ playerID: number; cuepointIndex: number }>());
export const setPitchAction = createAction(`${ACTION_TYPE} Set Pitch`, props<{ playerID: number; value: number }>());
export const setVirtualVelocityAction = createAction(
  `${ACTION_TYPE} Set Virtual Velocity`,
  props<{ playerID: number; positionMs: number; timems: number }>()
);
export const setVirtualVelocityAvgTimeAction = createAction(
  `${ACTION_TYPE} Set Virtual Velocity AvgTime`,
  props<{ playerID: number; positionMs: number; timems: number; received: number }>()
);
export const togglePitchModeAction = createAction(`${ACTION_TYPE} Toggle pitch mode`, props<{ playerID: number }>());
export const setPitchModeAction = createAction(`${ACTION_TYPE} Set pitch mode`, props<{ playerID: number; pitchMode: PitchMode }>());
export const togglePlayedToSyncToAction = createAction(`${ACTION_TYPE} Toggle player to sync to sync`, props<{ playerID: number }>());
export const activatePlayerAction = createAction(`${ACTION_TYPE} activate player`, props<{ playerID: number }>());
export const deactivatePlayerAction = createAction(`${ACTION_TYPE} deactivate player`, props<{ playerID: number }>());
export const switchActivePlayerAction = createAction(`${ACTION_TYPE} switch active player`, props<{playerIDToActive: number; playerIDToDeactivate: number}>())

export const startEndOfSongDisplayAnimationAction = createAction(`${ACTION_TYPE} Start end-of-song display animation`, props<{ playerID: number }>());
export const stopEndOfSongDisplayAnimationAction = createAction(`${ACTION_TYPE} Stop end-of-song display animation`, props<{ playerID: number }>());

export const setBpmAction = createAction(`${ACTION_TYPE} Set Bpm`, props<{ playerID: number, bpm: number }>());
export const triggerSoundplayerBpmContextMenuAction = createAction(`${ACTION_TYPE} trigger soundplayer bpm context menu`, props<{playerID: number, posX: number, posY: number}>())
export const switchBpmTpmAction = createAction(`${ACTION_TYPE} switch bpm and tpm`, props<{playerID: number, command: 'bpm' | 'tpm'}>());

export const highlightTrackInPlaylistAction = createAction(`${ACTION_TYPE} highlight track in playlist`, props<{playerID: number}>());
export const triggerTickerContextMenuAction = createAction(`${ACTION_TYPE} trigger ticker context menu`, props<{playerID: number, posX: number, posY: number}>());

export const setCurrentScratchingPositionAction = createAction(`${ACTION_TYPE} set current scratching position`, props<{playerID: number; positionInDegree: number}>());
export const scratchAction = createAction(`${ACTION_TYPE} scratching on waveform`, props<{playerID: number; scratchingInformation: ScratchingInformation, msPerPx: number}>());
export const scratchStartAction = createAction(`${ACTION_TYPE} start scratching on waveform`, props<{playerID: number; scratchingInformation: ScratchingInformation}>());
export const scratchEndAction = createAction(`${ACTION_TYPE} end scratching on waveform`, props<{playerID: number;}>());
export const setWasPlayingAction = createAction(`${ACTION_TYPE} remember if player was playing before scratching`, props<{playerID: number, wasPlaying: boolean}>());
export const setZoomAction = createAction(`${ACTION_TYPE} starts refreshes waveform cache on zoom`, props<{playerID: number, msPxRatio : number}>());
export const reloadWaveformCacheSuccessfulAction = createAction(`${ACTION_TYPE} refreshes waveform cache on zoom`, props<{playerID: number, waveformCache : WaveformCache}>());
export const updateWaveformDataAction = createAction(`${ACTION_TYPE} refreshes waveform cache`, props<{playerID: number, waveformCache : WaveformCache}>());

export const unloadFileFromPlayerAction = createAction(`${ACTION_TYPE} unload file from player`, props<{playerID: number}>());