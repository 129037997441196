<div class="root">
  <div class="waveform" #waveformContainer>
    <canvas #bgCanvas class="waveform-bg"></canvas>

    <div id="waveformSegmentContainer" class="waveform-fg" class="waveform-segment-container">
      <waveform-segment 
      class="waveform-segment" 
      *ngFor="let x of this.displaySegmentListObservable$ | async"
      [width]="x.segmentWidth"
      [height]="x.segmentHeight"
      [segmentPositionCanvasPx]="x.segmentPositionCanvasPx"
      [msPxRatioProvider]="x.msPxRatioProvider"
      [xPositionMs]="x.xPositionMs"
      [waveformCache]="x._waveformCache$.getValue()"
      ></waveform-segment>

    </div>
    <div class="waveform-fg">
      <!-- ADD segments for p1 here dynamically, then just move them around-->
      <!-- nothing painted on p1 in new vairant, but viewchild used for width && height for now so ndont comment out -->
      <canvas #p1Canvas [width]="waveformWidth$ | async" [height]="(waveformHeight$ | async) / 2"></canvas>
      <canvas #p2Canvas [width]="waveformWidth$ | async" [height]="(waveformHeight$ | async) / 2"></canvas>
    </div>
    <canvas
      id="fgCanvas"
      #fgCanvas
      class="waveform-fg"
      style="box-shadow:1px 1px 1px 1px black inset;position:absolute;"
    ></canvas>

  </div>
  <div class="test-buttons">
    <button class="mini-button" toggle="false" (click)="zoomOut()">zoom -</button>
    <button class="mini-button" toggle="false" (click)="zoomIn()">zoom +</button>
  </div>
</div>
