<div class="countdown-root" *ngIf="{
  days: days$ | async,
  hours: hours$ | async,
  minutes: minutes$ | async,
  seconds: seconds$ | async,
} as dateObs">

  <div class="countdown-timer">
    <div class="countdown-box countdown-box-spacing">
      <div class="box-number">
        {{ dateObs.days }}
      </div>
      <div class="box-text">
        {{ daysString }}
      </div>
    </div>
    <div class="countdown-box countdown-box-spacing">
      <div class="box-number">
        {{ dateObs.hours }}
      </div>
      <div class="box-text">
        {{ hoursString }}
      </div>
    </div>
    <div class="countdown-box countdown-box-spacing">
      <div class="box-number">
        {{ dateObs.minutes }}
      </div>
      <div class="box-text">
        {{ minutesString }}
      </div>
    </div>
    <div class="countdown-box">
      <div class="box-number">
        {{ dateObs.seconds }}
      </div>
      <div class="box-text">
        {{ secondsString }}
      </div>
    </div>
  </div>
</div>