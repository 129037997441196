import { catchError, from, map, of } from 'rxjs';
import { UMSound } from '../index';
import { Sound } from './Sound';
export class UMSoundModule {
    static createSystem$() {
        return from(UMSound.createSystem()).pipe(map((result) => {
            return new Sound(result.address);
        }), catchError((error) => {
            console.error('Failed to create UMSound system:', error);
            return of(null);
        }));
    }
    static deleteSystem(sound) {
        UMSound.deleteSystem({ address: sound.getAddress() });
    }
}
