<div class="root" [ngStyle]="{transform: this.waveformOffset}">
    <div class="waveformA">
        <div class="waveform waveform1" [ngStyle]="{'animation-play-state':(isPlaying1$|async)?'running':'paused'}"><img
                src="./assets/img/fake-waveform.png" height="46px"></div>
        <div class="waveform waveform2" [ngStyle]="{'animation-play-state':(isPlaying1$|async)?'running':'paused'}"><img
                src="./assets/img/fake-waveform.png"></div>
    </div>
    <div class="waveformB">
        <div class="waveform waveform1" [ngStyle]="{'animation-play-state':(isPlaying2$|async)?'running':'paused'}"><img
                src="./assets/img/fake-waveform.png"></div>
        <div class="waveform waveform2" [ngStyle]="{'animation-play-state':(isPlaying2$|async)?'running':'paused'}"><img
                src="./assets/img/fake-waveform.png"></div>
    </div>
</div>