import { createAction, props } from '@ngrx/store';

const ACTION_TYPE = '[Mixer]';

export const setLevelsAction = createAction(
  `${ACTION_TYPE} Set levels`,
  props<{ playerID: number; levelLeft: number; levelRight: number }>()
);
export const setEqHighAction = createAction(`${ACTION_TYPE} Set eq high`, props<{ playerID: number; value: number }>());
export const setEqMidAction = createAction(`${ACTION_TYPE} Set eq mid`, props<{ playerID: number; value: number }>());
export const setEqLowAction = createAction(`${ACTION_TYPE} Set eq low`, props<{ playerID: number; value: number }>());

export const killEqHighAction = createAction(`${ACTION_TYPE} Kill eq high`, props<{ playerID: number; }>());
export const killEqMidAction = createAction(`${ACTION_TYPE} Kill eq mid`, props<{ playerID: number; }>());
export const killEqLowAction = createAction(`${ACTION_TYPE} Kill eq low`, props<{ playerID: number; }>());

export const setFlangerAction = createAction(`${ACTION_TYPE} Set flanger`, props<{ playerID: number; value: number }>());
export const setResonanceAction = createAction(`${ACTION_TYPE} Set resonance`, props<{ playerID: number; value: number }>());
export const setCutoffAction = createAction(`${ACTION_TYPE} Set cutoff`, props<{ playerID: number; value: number }>());

export const killFlangerAction = createAction(`${ACTION_TYPE} Kill flanger`, props<{ playerID: number; }>());
export const killResonanceAction = createAction(`${ACTION_TYPE} Kill resonance`, props<{ playerID: number; }>());
export const killCutoffAction = createAction(`${ACTION_TYPE} Kill cutoff`, props<{ playerID: number; }>());

export const setVolumeAction = createAction(`${ACTION_TYPE} Set Volume`, props<{ playerID: number; volume: number }>());

export const crossfaderAction = createAction(`${ACTION_TYPE} Crossfader`, props<{ value: number }>());
