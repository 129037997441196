<div class="information-tile-root">
  <div class="information-tiles">
    <div class="info-box info-box-spacing-to-right">
      <div class="info-count">
        {{ informationTiles.playedTracks.count }}
      </div>
      <div class="info-text">
        {{ informationTiles.playedTracks.text }}
      </div>
    </div>

    <div class="info-box info-box-spacing-to-right">
      <div class="info-count">
        {{ informationTiles.countOfDjs.count }}
      </div>
      <div class="info-text">
        {{ informationTiles.countOfDjs.text }}
      </div>
    </div>

    <div class="info-box">
      <div class="info-count">
        {{ informationTiles.countOfCountries.count }}
      </div>
      <div class="info-text">
        {{ informationTiles.countOfCountries.text }}
      </div>
    </div>
  </div>
</div>