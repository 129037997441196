import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
// import { ResizeObserver } from '@juggle/resize-observer';
import { BehaviorSubject } from 'rxjs';
import { WaveformCache } from '../WaveformCache';
@Component({
  selector: 'bs-waveform',
  templateUrl: './bs-waveform.component.html',
  styleUrls: ['./bs-waveform.component.scss'],
})
export class BSWaveformComponent implements OnInit, AfterViewInit, OnDestroy {
  private _cacheContent: WaveformCache;
  private ctx: CanvasRenderingContext2D;
  private ro: ResizeObserver;

  get cacheContent(): WaveformCache {
    return this._cacheContent;
  }

  @Input() loading = false;

  @Input() set cacheContent(val: WaveformCache) {
    this._cacheContent = val;

    // console.log('bs-waveform.cacheContent changed');
    if (this._cacheContent != null) {
      console.log('cacheContent in bs-waveform-component set --> drawWaveform');
      this.drawFromWaveformCache();
    }
  }

  public waveformWidth$ = new BehaviorSubject(300);
  public waveformHeight$ = new BehaviorSubject(50);

  @ViewChild('waveformContainer', { static: false }) waveformContainer: ElementRef;
  @ViewChild('myCanvas', { static: false }) canvasRef: ElementRef;

  avgScale = 1;
  minMaxScale = 1;

  constructor() {}

  ngOnDestroy(): void {
    this.ro?.disconnect();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.ctx = (this.canvasRef.nativeElement as HTMLCanvasElement).getContext('2d');

    const func = (entries, observer) => {
      for (const entry of entries) {
        //  console.log('entry', entry);

        // TODO: resize erst nach einem bestimmten Delay, damit es nicht zu häufig passiert beim ändern der Browsergröße

        if (entry.target === this.waveformContainer.nativeElement) {
          this.waveformWidth$.next(entry.contentRect.width);
          this.waveformHeight$.next(entry.contentRect.height);
        }
        if (entry.target === this.canvasRef.nativeElement) {
          this.onCanvasResize();
        }
      }
    }; /// debounce, 250);

    this.ro = new ResizeObserver(func);
    this.ro.observe(this.waveformContainer.nativeElement);
    this.ro.observe(this.canvasRef.nativeElement);
  }

  private onCanvasResize() {
    this.drawFromWaveformCache();
  }

  // TODO: offscreen canvas
  drawFromWaveformCache() {
    if (!this._cacheContent) {
      return;
    }
    console.log('>> drawFromWaveformCache', this._cacheContent);

    const byteArray: Int8Array = this._cacheContent.getByteArrayFromCacheObj();
    // copied value from WaveformSegmentBuffer.BYTES_PER_PIXEL in waveformcontrol6-fx
    const BYTES_PER_PIXEL = this._cacheContent.BYTES_PER_PIXEL;
    const pixelCount = this._cacheContent.pixelCount;

    const mainColor = '#F9C20F';
    const lineWidth = 1;

    this.ctx.clearRect(0, 0, this.waveformWidth$.getValue(), this.waveformHeight$.getValue());

    /*
    ctx.fillStyle = 'red';
    ctx.fillRect(0, 0, 100, 50);

    if (this._cacheContent) {
      return;
    }
    */

    // DRAW BG and middle line
    // this.ctx.rect(0, 0, this.waveformWidth$.getValue(), this.waveformHeight$.getValue());
    // this.ctx.fillStyle = '#111111';
    // this.ctx.fill();
    this.ctx.beginPath();
    this.ctx.moveTo(0, this.waveformHeight$.getValue() / 2);
    this.ctx.lineTo(this.waveformWidth$.getValue(), this.waveformHeight$.getValue() / 2);
    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = 'gold';
    this.ctx.stroke();

    let minValue = 0;
    let maxValue = 0;
    for (let x = 0, i = 0; i + 7 < byteArray.length; x++, i += BYTES_PER_PIXEL) {
      // todo: understand this min max handling (correct?)
      // Bei den Min/Max-Werten ist das hier wichtig, weil durch Interferenz sonst Spitzen verschluckt
      // werden. Bei den Durchschnittswerten ist das egal, da wird schnell gesampelt.
      minValue = byteArray[i] * (1 / Math.sqrt(2)) - 0.5;
      /*if (byteArray[i] < minValue) {
          minValue = byteArray[i];
      }*/

      maxValue = byteArray[i + 1] * (1 / Math.sqrt(2)) + 0.5;
      /*if (byteArray[i + 1] > maxValue) {
          maxValue = byteArray[i + 1];
      }*/
      const avgNeg = byteArray[i + 2] * (1 - 1 / Math.sqrt(2)) - 0.5;
      const avgPos = byteArray[i + 3] * (1 - 1 / Math.sqrt(2)) + 0.5;

      const middle = this.waveformHeight$.getValue() / 2;
      const scale = this.waveformHeight$.getValue() / 255.0;
      // Durch die Rundung werden die sehr kleinen Werte um 1 Pixel besser sichtbar.
      // Bei den negativen Werten erzeugt nur -0.5 symetrische Bilder, auch round() ist falsch.
      const maxY1 = middle - (maxValue * scale + 0.5); // top of line, 0.5  is like ceil
      const minY2 = middle - (minValue * scale - 0.5); // bot of line, -0.5 is like floor
      // var lineH = maxY1 - minY2 + 1;//why +1?

      const lowPassAvg = Math.max(0, Math.min(255, byteArray[i + 4] * 2));
      const midPassAvg = Math.max(0, Math.min(255, byteArray[i + 5] * 2));
      const highPassAvg = Math.max(0, Math.min(255, byteArray[i + 6] * 2));
      const r = Math.max(0.0, Math.min(255, lowPassAvg));
      const g = Math.max(0.0, Math.min(255, midPassAvg));
      const b = Math.max(0.0, Math.min(255, highPassAvg));
      const minMaxColor = this.rgb256ToHexCode(r, g, b, 127);

      // maxY1 = middle - Math.abs(maxValue*scale);
      let lineH = Math.abs(minValue * scale) + Math.abs(maxValue * scale) + 1; // why +1

      this.ctx.beginPath();
      this.ctx.rect(x, maxY1, lineWidth, lineH);
      this.ctx.fillStyle = '#' + minMaxColor; // 'red';
      this.ctx.fill();

      // Durchschnitts-Waveform zusaetzlich auf die Maximum/Minimum-Waveform zeichnen.
      const avgPosY1 = middle - (avgPos * scale + 0.5);
      const avgNegY2 = middle - (avgNeg * scale - 0.5);

      // y = middle - Math.abs(avgPos*scale);
      lineH = Math.abs(avgNeg * scale) + Math.abs(avgPos * scale) + 1;

      this.ctx.beginPath();
      this.ctx.rect(x, avgPosY1, lineWidth, lineH);
      this.ctx.fillStyle = mainColor + '7F'; // 'gold';
      this.ctx.fill();
    }
    console.log('min:%s, max: %s, avgNeg: %s avgPos: %s', byteArray[0], byteArray[1], byteArray[2], byteArray[3]);
  }

  // test fuction triggered via button
  drawSomething() {
    const ctx = this.canvasRef.nativeElement.getContext('2d');
    console.log('called drawSomething()');

    // Create gradient
    const grd = ctx.createLinearGradient(0, 0, 200, 0);
    grd.addColorStop(0, 'red');
    grd.addColorStop(1, 'white');

    // Fill with gradient
    ctx.fillStyle = grd;
    ctx.fillRect(10, 10, 150, 80);

    // ctx.fillRect(0,0,300,300);
    // ctx.fill
    ctx.font = '30px Arial';
    ctx.strokeText('Hello World', 10, 50);
  }

  decToHex(dec) {
    let hex = Number(dec).toString(16);
    if (hex.length < 2) {
      hex = '0' + hex;
    }
    return hex;
  }

  /**
   * all rgba values in 0...255 range
   */
  rgb256ToHexCode(r, g, b, a) {
    const red = this.decToHex(r);
    const green = this.decToHex(g);
    const blue = this.decToHex(b);
    const alpha = this.decToHex(a);
    return red + green + blue + alpha;
  }
}
