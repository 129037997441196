import { Component, DestroyRef, Input, OnInit, inject, ChangeDetectionStrategy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, interval } from 'rxjs';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownComponent implements OnInit {
  @Input() targetDate: Date = new Date('2024-03-11'); // Input get from Firebase
  @Input() countdownRange: string = '7'; // Input get from Firebase

  targetDate$: BehaviorSubject<Date> = new BehaviorSubject<Date>(
    new Date(this.targetDate)
  );

  days$: BehaviorSubject<string> = new BehaviorSubject<string>('00');
  hours$: BehaviorSubject<string> = new BehaviorSubject<string>('00');
  minutes$: BehaviorSubject<string> = new BehaviorSubject<string>('00');
  seconds$: BehaviorSubject<string> = new BehaviorSubject<string>('00');

  daySteps$: BehaviorSubject<number> = new BehaviorSubject<number>(7);

  daysString = 'Tage';
  hoursString = 'Stunden';
  minutesString = 'Minuten';
  secondsString = 'Sekunden';

  private readonly destroy: DestroyRef = inject(DestroyRef);

  constructor() {
    this.daySteps$.next(parseInt(this.countdownRange));

    interval(1000)
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe(() => {
        this.calcDateValues();
      });
  }

  ngOnInit(): void { }

  calcDateValues(): void {
    const now = new Date();
    let targetDate = this.targetDate$.value;

    if (now.getTime() >= targetDate.getTime()) {
      targetDate = new Date(new Date().setDate(new Date().getDate() + 7));
      this.targetDate$.next(targetDate);
    }

    const timeDifference = targetDate.getTime() - now.getTime();
    if (timeDifference > 0) {
      const seconds = Math.floor(timeDifference / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      this.days$.next(this.formatValue(days));
      this.hours$.next(this.formatValue(hours % 24));
      this.minutes$.next(this.formatValue(minutes % 60));
      this.seconds$.next(this.formatValue(seconds % 60));
    }
  }

  formatValue(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }
}
