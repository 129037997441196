import { registerPlugin } from '@capacitor/core';
const UMSound = registerPlugin('UMSound', {
    web: () => import('./web').then(m => new m.UMSoundWeb()),
    electron: () => window.CapacitorCustomPlatform.plugins.UMSound,
});
export * from './definitions';
export * from './umsound/Player';
export * from './umsound/Sound';
export * from './umsound/UMSoundModule';
export * from './umsound/UMSoundResult';
export * from './umsound/UMSoundOutputType';
export * from './umsound/ScratchInformation.interface';
export { UMSound };
