import { Component, OnInit } from '@angular/core';
import { InformationTiles } from '../../core/interfaces/information-tiles.interface';

// ! This is only a component for develop and test a component with inputs
@Component({
  selector: 'app-test-component-for-inputs',
  templateUrl: './test-component-for-inputs.component.html',
  styleUrls: ['./test-component-for-inputs.component.scss']
})
export class TestComponentForInputsComponent implements OnInit {
  informationTiles: InformationTiles = {
    playedTracks: { text: 'gespielte Tracks', count: 3432 },
    countOfDjs: { text: 'UltraMixer Djs', count: 4432 },
    countOfCountries: { text: 'Länder', count: 12 },
  };

  constructor() { }

  ngOnInit(): void { }
}
