import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { CountdownComponent } from './countdown/countdown.component';
import { InformationTilesComponent } from './information-tiles/information-tiles.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TestComponentForInputsComponent } from './text-component-for-inputs/test-component-for-inputs.component';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule],
  declarations: [SpinnerComponent, InformationTilesComponent, TestComponentForInputsComponent, CountdownComponent],
  exports: [SpinnerComponent, InformationTilesComponent, TestComponentForInputsComponent, CountdownComponent],
})
export class ComponentsModule {}
