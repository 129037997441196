import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UMSoundInputTransformerService {

  constructor() { }

  /**
   * @param value -1 - 1
   * @returns value transformed to 0 - x 
   * where x is set max and range between 0 - 1 represent slower
   * for example 2x doubles the speed while 0.5x halves the speed
   */
  pitch(value : number) : number {
    if(value < 0) {
      return (1 /  (1 + Math.abs(value))); //add * max inside the () later for scaling to lower speeds
    }
    
    return (value + 1) //add * max later for scaling to higher speeds
  }

  /**
   * @param value -1 - 1
   * @returns value transformed to 0 - x 
   * where x is set max and range between 0 - 1 represent slower
   * for example 2x doubles the speed while 0.5x halves the speed
   */
  tempo(value : number) : number {
    if(value < 0) {
      return (1 /  (1 + Math.abs(value))); //add * max inside the () later for scaling to lower speeds
    }

    return (value + 1) //add * max later for scaling to higher speeds
  }

  /**
   * @param value 0 - 10000
   * @returns value transformed to 0 - 1
   */
  volume(value : number) : number {
    return value / 10000;
  }

  /**
   * @param value 0 - 10000
   * @returns value transformed to 1 - 10
  */
  resonance(value: number): number {
    return (value / 10000) * 9 + 1;
  }

  /**
   * @param value 0 - 10000
   * @returns value transformed to 1 - 10
  */
  flangerMix(value: number): number {
    let v = 200 - value / 50;
    let x = 10000 - (0.7 * v * v * 10000 / (40000 * 0.7));
    let mix = x / 100;
    return mix;
  }

  /**
   * @param value 0 - 10000
   * @returns value transformed to 10 - 22000
  */
  cutoff(value: number): number {
    return (value / 10000) * (22000 - 10) + 10;
  }

  /**
   * @param value 0 - 10000
   * @returns value transformed to -30 - 30
  */
  eq(value: number): number {
    return (value / 10000) * (30 - - 30) - 30;
  }

}
